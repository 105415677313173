import {NgModule, Injectable} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
    HttpClientModule,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpRequest,
    HTTP_INTERCEPTORS,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {RouterModule, Router, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { from } from 'rxjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FoodDirective } from './directive/food.directive';
import { NumbersOnlyDirective } from './directive/numbers-only.directive';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import {NumberPickerModule} from 'ng-number-picker';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { CommonModule, TitleCasePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { DishesComponent } from './components/dishes/dishes.component';
import { CashiersComponent } from './components/cashiers/cashiers.component';
import { CreateBillComponent } from './components/create-bill/create-bill.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OrdersComponent } from './components/orders/orders.component';
import { ViewBillComponent } from './components/view-bill/view-bill.component';
import { BranchesComponent } from './components/branches/branches.component';
import { HallsComponent } from './components/halls/halls.component';
import { ExpenseComponent } from './components/expense/expense.component';
import { UsersComponent } from './users/users.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReportsComponent } from './components/reports/reports.component';
import { FooterComponent } from './components/footer/footer.component';
import { SettingsComponent } from './components/settings/settings.component';
import { OrdersDisplayComponent } from './orders-display/orders-display.component';
import { ViewOrdersComponent } from './view-orders/view-orders.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(err => this.handleError(err)));
    }


    private handleError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 || err.status === 403) {
            this.router.navigateByUrl(`/login`);
            return of(err.message);
        }
        return Observable.throw(err);
    }
}
// const appRoutes: Routes = [
//   { path: '**', component: AppComponent },
// ];


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    SidemenuComponent,
    DishesComponent,
    CashiersComponent,
    CreateBillComponent,
    DashboardComponent,
    OrdersComponent,
    ViewBillComponent,
    FoodDirective,
    BranchesComponent,
    HallsComponent,
    ExpenseComponent,
    NumbersOnlyDirective,
    UsersComponent,
    UnauthorizedComponent,
    PageNotFoundComponent,
    ReportsComponent,
    FooterComponent,
    SettingsComponent,
    OrdersDisplayComponent,
    ViewOrdersComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    AutocompleteLibModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      positionClass: 'toast-top-right',
      timeOut: 3000,
      progressBar : true,
      progressAnimation: 'increasing',
    }),
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TabsModule.forRoot(),
    AngularMultiSelectModule,
    NumberPickerModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
        backdropBackgroundColour: '#fff', 
        backdropBorderRadius: '25px',
        primaryColour: '#d04344', 
        secondaryColour: '#d04344', 
        tertiaryColour: '#d04344'
    })
  ],
  providers: [TitleCasePipe,{
    provide: HTTP_INTERCEPTORS,
    useFactory: function(router:Router) {
      return new AuthInterceptor(router)
    },
    multi: true,
    deps: [Router]
  },
  {provide: LocationStrategy, useClass: HashLocationStrategy}
],
  bootstrap: [AppComponent]
})
export class AppModule { }
