import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import Pusher from 'pusher-js';
import {Howl, Howler} from 'howler';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    // role: any = `${localStorage.getItem('role_name')}`;
    // roleId: any = `${localStorage.getItem('role_id')}`;
    userName = `${localStorage.getItem('dispatch_name')}`;
    branchName = `${localStorage.getItem('location_name')}` === null ? '' : `${localStorage.getItem('location_name')}`;
    id = `${localStorage.getItem('location_details_id')}`;

    sound = new Howl({
      src: ['../../../assets/sound/goes-without-saying-608.mp3']
    });

    pusher = new Pusher('8d0ad959b5759a0aa3ca', {
      cluster: 'ap2'
    });
    constructor(private apiService: ApiService, private router: Router) { }

    ngOnInit() {
        var channel = this.pusher.subscribe('blaack-forest');
        channel.bind(`shop-${this.id}`, (data) => {
          this.showNotification(data);
        // this.checkNotificationPermission(data);
      });
    }
    checkNotificationPermission(data){
      // console.log(Notification.permission);
        if(Notification.permission ==="granted"){
            this.showNotification(data);

        }else if(Notification.permission!=="denied"){
          Notification.requestPermission().then(permission=>{
                if(permission ==="granted"){
                  this.showNotification(data);
                }
            });
      }
    }
    showNotification(data){
      this.sound.play();
      // console.log(data)
      // console.log(this.router.url);
      const notification=new Notification(data.title,{
          body: data.message,
          icon: '../../../assets/images/bf-logo-square.png',
          image:data.imageUrl
      });

    }

    logout() {
        this.router.navigate(['login']);
        this.apiService.getData('userLogout').subscribe((data) => {
            localStorage.clear();
            location.reload();
        });
    }


}
